import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import Layout from '../layouts/Layout'
import SiteMetadata from '../components/SiteMetadata'
import { useSiteInfoQuery } from '../queries/useSiteInfoQuery'
import { MarkdownProcessor } from '../components/Markdown/MarkdownProcessor'
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { theme } from '../styles/theme'

const AboutPage = ( { data } ) => {
    const { siteDescription } = useSiteInfoQuery()

    const { bio, myPortrait } = data.contentfulAbout
    const { email } = data.contentfulContact

    const stylez = css`
            width: 50%;
            margin: 0 auto;
            font-size: 1.2rem;
            ${ theme.breakpoints.mobile } {
            width: 95%;
            font-size: 1.2rem;
            }
            ${ theme.breakpoints.tablet } {
              width: 85%;
              font-size: ${ theme.fontSizes.medium };
            }
            ${ theme.breakpoints.largeDesktop } {
              font-size: 1.5rem;
              }
      .bio {
        font-weight: 100;

      }
      section {
            margin-right: 2rem;
            font-weight: 300;
          }
      a {
        display: inline;
        color: ${ theme.colors.secondary };
        :visited {
                color: ${ theme.colors.secondary };
              }
      }
      .email_wrapper {
          width: 100%;
          margin: 4rem 0;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          a {
            font-weight: 100;
          }
          ${ theme.breakpoints.mobile } {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
          }
          a:hover {
            text-decoration: underline;
          }

        }
        .news_wrapper {
          margin: 4rem 0;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          span {
            line-height: 1.5em;
            &.bold {
              font-weight: 300 !important;
            }
          }

        }
      .my_portrait {

      }
    `

    return (
        <Layout>
            <SiteMetadata title='About' description={ siteDescription } />

            <div
              className='text_content about_wrapper'
              css={ stylez }
            >

                <div className='bio'>
                    { bio.bio }
                </div>

                {
                    data.contentfulAbout.news ?
                    <div className='news_wrapper'>
                        <section>
                            Aktuell:
                        </section>
                        <MarkdownProcessor
                          markdownElement={ news }
                        />
                    </div>
                    : null
                }

                <div className='email_wrapper'>
                    <section>
                        Kontakt:
                    </section>
                    <a
                      href={ `mailto:${ email }` }
                      className='email'
                    >
                        { email }
                    </a>

                </div>

                <div className='my_portrait'>
                    <Img fluid={ myPortrait && myPortrait.localFile.childImageSharp.fluid } alt='my portrait' />

                </div>

            </div>

        </Layout>
    )
}

export default AboutPage

export const query = graphql`
  query {
    contentfulAbout {
      bio {
        bio
      }
      myPortrait {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1400, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    contentfulContact {
      email
    }
  }

`
